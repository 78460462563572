<template>

  <body>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><router-link to="/info">个人中心</router-link>><a
            href="javascript:;">收件地址</a>
        </div>
      </div>
      <div class="personal per_info pt30 m1440 flex-box flex-col-start">
        <div class="left_nav fs18">
          <router-link to="/info">基本信息</router-link>
          <router-link to="/certificate">证书抬头</router-link>
          <router-link to="/maintain_list">开票信息</router-link>
          <router-link to="/address_list" class="active">收件地址</router-link>
          <router-link to="/invoices_status">发票管理</router-link>
          <!-- mao 20250313 -->
          <!-- <router-link to="/contract_management">合同管理</router-link> -->
          <!-- end -->
          <router-link to="/change_password">修改密码</router-link>
          <router-link to="/change_phone">修改手机号</router-link>
          <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
          <!-- <router-link to="/per_about">关于我们</router-link> -->
        </div>
        <div class="right_box flex-grow-1">
          <div class="fs18 fwb pb30">收件地址 
            <a href="javascript:;" class="add_certificate fs16 ml0" @click="closePop()">新增收件地址</a>
          </div>
          <div class="flex-box flex-col-start">
            <div v-if="address_list.length <= 0">
              <div class="no_data">
                <img src="../assets/static/icon/z_nothing.png" class="z_nothing" alt="" />
                <span>暂无数据</span>
              </div>
            </div>
            <ul class="address_list flex-grow-1 fs16" v-else>
              <li v-for="(item, index) in address_list" :key="index">
                <div class="default" v-if="item.status == 1">默认</div>
                <img src="../assets/static/icon/icon_close.png" class="icon_close" alt="" @click="del_address(index)" />
                <div class="flex-box edit_btn fs16" @click="edit_address(index)">编辑</div>
                <div class="flex-box pb25">
                  <div class="info_left">收件人：</div>
                  <div>{{ item.receiverName }}</div>
                </div>
                <div class="flex-box pb25">
                  <div class="info_left">详细地址：</div>
                  <div>{{ item.region }}{{ item.addressDetail }}</div>
                </div>
                <div class="flex-box">
                  <div class="info_left">联系电话：</div>
                  <div>{{ item.receiverTel }}</div>
                </div>
              </li>
            </ul>
          </div>
          <!-- <a href="javascript:;" class="add_address fs16" @click="closePop">新增收件地址</a> -->
          <div v-if="address_list.length > 0">
            <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination>
          </div>
        </div>
        <!-- <div class="nomore" v-if="isShowNavBarLoading">没有更多了~</div> -->
        <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
      </div>
    </div>
    <!-- 收件地址 -->
    <Popup :visible="popup" :confirmText="false" :cancleText="false" @close="closePop">
      <div class="name_pop">
        <div class="flex-box name_title flex-between">
          <div class="fs20">{{ address_type == 0 ? '新增' : '编辑' }}收件地址</div>
          <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="closePop()" alt="" />
        </div>
        <div class="cirticle_info">
          <div class="must fs16 col9 info_title">收件人</div>
          <input type="text" class="input1" v-model="current.receiverName" placeholder="请输入收件人" />
          <div class="must fs16 col9 info_title">所在地区</div>
          <el-cascader size="large" class="outer flex-box flex-between" :options="options" v-model="selectedOptions"
            @change="handleChange" :placeholder="current.region ? current.region : '请选择'">
          </el-cascader>
          <div class="must fs16 col9 info_title">详细地址</div>
          <input type="text" class="input1" v-model="current.addressDetail" placeholder="请填写详细地址" />
          <div class="must fs16 col9 info_title">联系电话</div>
          <input type="text" class="input1" v-model="current.receiverTel" placeholder="请填写联系电话" />
          <div class="flex-box">
            <div class="fs16 col9 mr30">设为默认收件地址</div>
            <div class="switch" :class="current.status == '1' ? 'on' : ''" @click="setDefault()"></div>
          </div>
          <div class="save" @click="add_address">保存</div>
        </div>
      </div>
    </Popup>
    <Footer></Footer>
  </body>
</template>
<script>
import cookie from "vue-cookies";
import axios from "axios";
import { regionData, CodeToText } from "element-china-area-data";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import Pagination from "@/components/pagination.vue";
export default {
  components: {
    Pagination,
  },
  name: "info",
  data() {
    return {
      popup: false,
      userinfo: {},
      navIndex: 1,
      options: regionData,
      selectedOptions: [],
      address_list: [],
      pageSize: 15, //分页大小
      total: 0, //分页总数
      pageNum: 1, // 分页记录数
      isShowNavBarLoading: true, // 是否加载完
      current: {
        receiverName: '',
        receiverTel: '',
        addressDetail: '',
        status: '0',
        region: ''
      },
      address_type: 0,//0:新增,1:编辑
      e_index: -1,
      mailPopup: false,
    };
  },

  created() {
    this.oss = this.global.uploadOss
    this.get_user_info()
    this.get_address()
  },
  methods: {
    //添加或编辑地址
    add_address() {
      let that = this
      let type = that.address_type
      let current = that.current
      if (!current.receiverName) {
        that.$message.error('请输入姓名');
        return;
      }
      if (!current.receiverTel) {
        that.$message.error('请输入联系电话');
        return;
      }
      if (!current.region) {
        that.$message.error('请选择城市');
        return;
      }
      if (!current.addressDetail) {
        that.$message.error('请输入详细地址');
        return;
      }
      let list = that.address_list
      let e_index = that.e_index
      if (type == 0) {
      } else {
        current.id = list[e_index].id
      }
      that.$api.addressEdit(current).then((res) => {
        if (res.resCode == 0) {
          that.$message.success('提交成功');
          setTimeout(function () {
            that.address_type = 0
            that.e_index = -1
            that.popup = !that.popup;
            that.current = {
              receiverName: '',
              receiverTel: '',
              addressDetail: '',
              status: '0',
              region: ''
            },
              that.get_address()
          }, 800)
        } else {
          that.$message.error(res.resMsg);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    //选择省市区
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]] + "-";
      }
      let city = loc.split("-")[1]
      if (city == '市辖区') {
        this.current.region = loc.split("-")[0] + loc.split("-")[2]
      } else {
        this.current.region = loc.split("-")[0] + loc.split("-")[1] + loc.split("-")[2]
      }
    },

    //删除地址
    del_address(index) {
      let that = this;
      let list = that.address_list

      MessageBox.confirm('是否确认删除该地址', "温馨提示", { type: "warning" })
        .then((rec) => {
          // 判断 是否是 确认
          if (rec == "confirm") {
            that.$api.addressDel({ id: list[index].id }).then((res) => {
              console.log(res)
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                list.splice(index, 1)
                that.address_list = list
                that.total = that.total - 1;
              } else {
                that.$message.error(res.resMsg);
              }
            });
          } else {
            // 取消操作
          }
        })
    },
    edit_address(index) {
      this.address_type = 1
      this.e_index = index
      this.current = this.address_list[index]
      this.popup = !this.popup;
    },

    closePop() {
      this.address_type = 0
      this.e_index = -1
      this.popup = !this.popup;
    },
    // 默认抬头
    setDefault() {
      let _that = this;
      let status = this.current.status
      if (status == '1') {
        var s_status = '0'
      } else {
        var s_status = '1'
      }
      _that.current.status = s_status;
    },


    // 分页
    pageTurn(page) {
      this.pageNum = page + 1;
      this.get_address();
    },
    // 获取地址列表
    get_address(s_param = {}) {
      var that = this;
      let page = that.pageNum;
      s_param.page = page;
      s_param.limit = that.pageSize;
      s_param.sortProp = 'status',
      s_param.sortType = 'DESC'
      this.$api.apiGetAddress(s_param).then((data_res) => {
        //console.log(data_res);
        if (data_res.resCode == 0) {
          if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
            that.isShowNavBarLoading = true;
          } else {
            // 判断 是否 加载完毕
            var is_data = true;
            var totalCount = data_res.totalCount;
            var show_total = that.pageNum * that.pageSize;
            if (totalCount > show_total) {
              //当只有一页的时候展示
              is_data = true;
            } else {
              is_data = false;
            }
            that.total = totalCount;
            that.address_list = data_res.root;
            that.isShowNavBarLoading = is_data;
          }
        } else {
          that.$message.error(data_res.msg);
        }
      });
    },


    get_user_info() {
      let that = this;
      let user = cookie.get("huanbaoqiao_userinfo");
      let token = cookie.get("huanbaoqiao_token");
      if (!user || !token) {
        this.$message.error('请先登录');
        this.$router.push("/login")
        return;
      }
      that.$api.apiUserInfo({ deviceType: '1', id: user.id ? user.id : 0 }).then((res) => {
        console.log(res)
        if (res.resCode == 0) {
          // console.log(res.root)
          if (res.root[0].avatar != null && res.root[0].avatar != '') {
            that.oss_avatar = res.root[0].avatar
          }
          cookie.set("huanbaoqiao_userinfo", res.root[0])
          that.userinfo = res.root[0]
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

    //删除
    cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id }).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });

          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },

    close() {

    }
  },
};
</script>
